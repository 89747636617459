<template>
  <div class="wf-scroll">
    <header class="modal-header" v-if="!workFlowFlag">
      <h5 class="modal-title">{{ workflowName }}</h5>
    </header>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'wfSummary',
  data() {
    return {
      urlData: null,
      workflowName: 'Workflow Summary',
      menuId: null,
      workFlowFlag: false
    };
  },
  mounted() {
    if (this.$route.params.summaryData) {
      const decodUrl = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
      sessionStorage.setItem('token', decodUrl.token);
      this.urlData = decodUrl;
      if (this.$route.params.wfType === 'upload-doc') {
        this.workFlowFlag = true;
        this.menuId = this.urlData.menu_id;
      } else {
        this.menuId = this.urlData.wf_menu_id;
      }
      this.getMenuNameByMenuId();
    }
    this.$store.dispatch('shared/setStandAloneSummary', true);
  },
  methods: {
    getMenuNameByMenuId() {
      this.$store.dispatch('menu/getMenuDetail', this.menuId).then(resp => {
        if (resp.status === 200) {
          if (resp.data.data) {
          this.workflowName = resp.data.data.menu_name;
          }
        }
      });
    }
  }
};
</script>
<style>
.wf-scroll {
  background-color: white;
  overflow: auto;
  height: calc(100vh - 25px) !important;
  margin: 5px 10px 5px 10px;
}
</style>
